<template>
  <div class="cntIzin">
    <div class="row mt-3 text-center text-white">
      <div class="col-3">
        <div class="card bg-info">
          <a href="/list-izin" class="text-decoration-none text-white">
            <div class="card-body">
              <h4 class="card-title">{{ izin }}</h4>
              <p class="card-text text-white text-wrap" title="izin">
                total izin
              </p>
            </div>
          </a>
        </div>
      </div>
      <div class="col-3">
        <a href="/list-sakit" class="text-decoration-none text-white">
          <div class="card bg-warning">
            <div class="card-body">
              <h4 class="card-title">{{ sakit }}</h4>
              <p class="card-text text-white text-wrap" title="sakit">
                total sakit
              </p>
            </div>
          </div>
        </a>
      </div>
      <div class="col-3">
        <a href="/list-cuti" class="text-decoration-none text-white">
          <div class="card bg-success">
            <div class="card-body">
              <h4 class="card-title">{{ cuti }}</h4>
              <p class="card-text text-white text-wrap" title="cuti">
                total cuti
              </p>
            </div>
          </div>
        </a>
      </div>
      <div class="col-3">
        <div class="card bg-primary" v-if="sisacuti >= 0">
          <div class="card-body">
            <h4 class="card-title">{{ sisacuti }}</h4>
            <p class="card-text text-white text-wrap" title="sisa cuti">
              sisa cuti
            </p>
          </div>
        </div>
        <div class="card bg-danger" v-else>
          <div class="card-body">
            <h4 class="card-title">{{ Math.abs(sisacuti) }}</h4>
            <p class="card-text text-white text-wrap" title="sisa cuti">
              utang cuti
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-3"
      v-if="
        jabatan == 'HR MANAGEMENT' || jabatan == 'OWNER' || jabatan == 'DIREKSI'
      "
    >
      <div class="text-center">
        <span class="badge bg-warning text-dark"> Approval </span>
      </div>
      <div class="row mt-3 text-center text-dark">
        <div class="col-1"></div>
        <div class="col-5">
          <a href="/list-cuti-approve" class="text-decoration-none">
            <div class="card bg-secondary text-white">
              <div class="card-body">
                <h4 class="card-title">{{ approve_cuti }}</h4>
                <p
                  class="card-text text-white text-truncate"
                  title="approval cuti"
                >
                  cuti menunggu di approve
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-5">
          <a href="/list-ijin-approve" class="text-decoration-none">
            <div class="card bg-secondary text-white">
              <div class="card-body">
                <h4 class="card-title">{{ approve_ijin }}</h4>
                <p
                  class="card-text text-white text-truncate"
                  title="approval ijin"
                >
                  ijin menunggu di approve
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-1"></div>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");

export default {
  data() {
    return {
      izin: 0,
      sakit: 0,
      cuti: 0,
      sisacuti: 0,
      approve_cuti: 0,
      approve_ijin: 0,
      jabatan: localStorage.getItem("jabatan"),
    };
  },
  created() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("key"),
    };

    try {
      axios
        .post(
          this.UrlApi + "dataizincutipic",
          {
            id: localStorage.getItem("id"),
            jabatan: localStorage.getItem("jabatan"),
          },
          { headers: headers }
        )
        .then((res) => {
          if (res.data.success && res.data.success !== null) {
            // console.log(res.data.data);
            this.izin = res.data.data.izin;
            this.cuti = res.data.data.cuti_new.cuti_tahun_ini;
            this.sakit = res.data.data.sakit;
            this.approve_cuti = res.data.data.approve_cuti;
            this.approve_ijin = res.data.data.approve_ijin;
            let hasil_sisa_cuti;
            if (res.data.data.cuti_new.sisa_cuti_tahun_sebelumnya > 0) {
              hasil_sisa_cuti =
                Math.abs(res.data.data.cuti_new.hak_cuti) -
                Math.abs(res.data.data.cuti_new.cuti_tahun_ini);
            } else {
              hasil_sisa_cuti =
                Math.abs(res.data.data.cuti_new.hak_cuti) -
                Math.abs(res.data.data.cuti_new.sisa_cuti_tahun_sebelumnya) -
                Math.abs(res.data.data.cuti_new.cuti_tahun_ini);
            }
            console.log(hasil_sisa_cuti);
            this.sisacuti = hasil_sisa_cuti;
            localStorage.setItem(
              "ijin",
              JSON.stringify([
                {
                  izin: res.data.data.izin,
                  cuti: res.data.data.cuti,
                  sisacuti: hasil_sisa_cuti,
                  sakit: res.data.data.sakit,
                  cuti_diambil: res.data.data.cuti_new.pengajuan_cuti,
                  cuti_bersama: res.data.data.cuti_new.cuti_bersama,
                  cuti_tahun_kemarin:
                    res.data.data.cuti_new.sisa_cuti_tahun_sebelumnya,
                },
              ])
            );
          } else {
            this.empty = true;
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
          this.empty = true;
        });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
